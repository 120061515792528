<template>
  <div>
    <div class="cardError">
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <div class="col-md-12">
            <div class="align-items-center justify-content-between">
              <div class="mt-2 mr-2">
                <CRow>
                  <CCol md="8" class="text-primary">
                    <h5>Record and Track Your Results Here</h5>
                  </CCol>
                  <CCol md="4">
                    <div class="mr-2 d-flex justify-content-end">
                      <CButton
                        class="mr-2 btn-primary"
                        style="height: 32px"
                        v-c-tooltip="'Click to Save record!'"
                        @click="onSubmit()">
                        Save
                      </CButton>
                      <CButton
                        class="mr-2 btn-secondary"
                        style="height: 32px"
                        v-c-tooltip="'Cancel'"
                        @click="clearFields()">
                        Cancel
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </div>
            <ValidationObserver ref="languageScore" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                name="languageScore">
                <CRow class="mt-2">
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label
                        name="language_exam_label"
                        class="col-lg-12 col-md-12 required"
                        >Exam/Test Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }">
                          <Select
                            name="language_exam"
                            :value="candidate_test.language_exam"
                            @input="handleChangeSelect"
                            :options="
                              (options && options['languageExam']) || []
                            "
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="language_label"
                        class="col-lg-12 col-md-12 required"
                        >Qualification/Training Category</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }">
                          <Select
                            name="course_test_type_id"
                            :value="candidate_test.course_test_type_id"
                            :options="
                              (options && options['courseTestType']) || []
                            "
                            @input="handleChangeSelect"
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="language_countries"
                        class="col-lg-12 col-md-12"
                        >Language</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <!-- <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"> -->
                          <Select
                            name="language_id"
                            :value="candidate_test.language_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['languageList']
                                ? options['languageList']
                                : []
                            "/>
                        <!-- </ValidationProvider> -->
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="date_obtained_label"
                        class="col-lg-12 col-md-12 required"
                        >Date Obtained</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          name="date_obtained"
                          rules="required|date_validate|no_future_date"
                          v-slot="{ errors }">
                          <DateInput
                            :onlyMonthAndYear="false"
                            name="date_obtained"
                            :value="candidate_test.date_obtained"
                            @change="handleDatePickerChange"
                            :pastYearLimit="10"
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="test_taken"
                        class="col-lg-12 col-md-12 required"
                        >Type of test taken</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }">
                          <Select
                            name="test_type"
                            :value="candidate_test.test_type"
                            :options="
                              (options && options['testTaken']) || []
                            "
                            @input="handleChangeSelect"
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="assessment_test_label"
                        class="col-lg-12 col-md-12 required"
                        >Was this result necessary for a job abroad?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }">
                          <RadioButton
                            name="assessment_test"
                            :value="candidate_test.assessment_test"
                            :options="(options && options['boolean']) || []"
                            @change="handleChangeRadio"
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="6" v-if="payload.assessment_test">
                    <CRow class="d-flex align-items-center mb-3">
                      <label
                        name="countries"
                        class="col-lg-12 col-md-12 required"
                        >Select the country you’d like to apply this test result to
                    </label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }">
                          <Select
                            name="language_country"
                            :value="candidate_test.language_country"
                            @input="handleChangeSelect"
                            :options="
                              options && options['countryList']
                                ? options['countryList']
                                : []
                            "
                            :error="errors[0]" />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <!-- OET -->
                <div v-if="isOET">
                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p class="text-primary font-weight-bold ml-3 mb-1 mb-1">
                        Listening
                      </p>
                    </CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="oet_listening_score"
                                :value="candidate_test.oet_listening_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="listening_score"
                                :value="candidate_test.listening_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options && options['listeningScores']) || []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="listening_grade"
                                :value="candidate_test.listening_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>

                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 required">
                        Reading
                      </p>
                    </CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="reading"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="oet_reading_score"
                                :value="candidate_test.oet_reading_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="reading_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="reading_score"
                                :value="candidate_test.reading_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options && options['readingScores']) || []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="reading_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="reading_grade"
                                :value="candidate_test.reading_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>

                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 required">
                        Speaking
                      </p>
                    </CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="speaking"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="oet_speaking_score"
                                :value="candidate_test.oet_speaking_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="speaking_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="speaking_score"
                                :value="candidate_test.speaking_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options && options['speakingScores']) || []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="speaking_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="speaking_grade"
                                :value="candidate_test.speaking_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>

                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 required">
                        Writing
                      </p>
                    </CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="writing"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="oet_writing_score"
                                :value="candidate_test.oet_writing_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="writing_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="writing_score"
                                :value="candidate_test.writing_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options && options['writingScores']) || []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="writing_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="writing_grade"
                                :value="candidate_test.writing_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>
                </div>
                <!-- IELTS -->
                <div v-else-if="isIelts">
                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 mb-1 required">
                        Academic Reading
                      </p>
                    </CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="academic_reading"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="ielts_academic_reading_score"
                                :value="candidate_test.ielts_academic_reading_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="academic_reading_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="academic_reading_score"
                                :value="candidate_test.academic_reading_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options &&
                                    options['academicReadingScores']) ||
                                  []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="academic_reading_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="academic_reading_grade"
                                :value="candidate_test.academic_reading_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>
                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 required">
                        General Reading
                      </p>
                    </CRow>

                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="general_reading"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="ielts_general_reading_score"
                                :value="candidate_test.ielts_general_reading_score"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="general_reading_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="general_reading_score"
                                :value="candidate_test.general_reading_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options &&
                                    options['generalReadingScores']) ||
                                  []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="general_reading_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="general_reading_grade"
                                :value="candidate_test.general_reading_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>

                  <CRow>
                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <p
                        class="text-primary font-weight-bold ml-3 mb-1 required">
                        Listening
                      </p>
                    </CRow>

                    <CRow class="col-lg-12 col-md-12 col-sm-12">
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Score</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="ielts_listening_scores"
                                :value="candidate_test.ielts_listening_scores"
                                @input="handleInput"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening_score_label"
                            class="col-lg-12 col-md-12 required"
                            >Result</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <Select
                                name="ielts_listening_score"
                                :value="candidate_test.ielts_listening_score"
                                @input="handleChangeSelect"
                                :options="
                                  (options &&
                                    options['ieltsListeningScores']) ||
                                  []
                                "
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="listening_grade_label"
                            class="col-lg-12 col-md-12"
                            >Grade</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }">
                              <TextInput
                                name="ielts_listening_grade"
                                :value="candidate_test.ielts_listening_grade"
                                :disabled="true"
                                :error="errors[0]" />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CRow>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-if="isCandidateScore">
            <CCard
            style="background-color: #f5f5f5">
              <CCardBody>
              <h5>Exam/Test Results</h5>
                <table class="table table-responsive-sm table-responsive-md">
                  <thead>
                    <tr>
                      <th>Test Type</th>
                      <th>Date Obtained</th>
                      <th>Result</th>
                      <th>CEFR Equivalent</th>
                      <th>Valid Until</th>
                      <th>Licensing Region Analysis</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  <template v-for="(scores, index) in getCandidateTestScores">
                    <tr>
                      <td>{{ scores.test_type }}</td>
                      <td>{{ scores.date_obtained }}</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>

                      <td>
                        <div class="text-primary h4"  
                          v-c-tooltip="{
                          content: 'Expand Scores',
                          }">
                          <em class="fa-solid fa-circle-plus"  @click="toggleInnerTable(index)"></em>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="showInnerTable[index]">
                      <th>Language Exam</th>
                      <th>Country</th>
                      <th>Language</th>
                      <th v-for="skill in scores.skills">{{ skill.skills }}</th>
                    </tr>
                    <tr v-if="showInnerTable[index]" style="background-color: white;">
                      <td>{{ scores.language_exam }}</td>
                      <td>{{ scores.country_name }}</td>
                      <td>{{ scores.language }}</td>
                      <td v-for="skill in scores.skills">{{ skill.score }}({{ skill.grade }})</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              </CCardBody>
            </CCard>
          </div>
        </CCardBody>
      </CCard>
    </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";

import Vue from "vue";
import m from "moment";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";

export default {
  name: "LanguageScore",
  components: {
    Select,
    TextInput,
    RadioButton,
    DateInput,
  },
  data() {
    return {
      candidate_test: {},
      payload: {},
      country_list: [],
      cardHasError: "",
      isMobile: false,
      showInnerTable: {}
    };
  },
  watch: {
    "candidate_test.language_exam"(newScore) {
      if (newScore === null) {
        this.clearFields();
      }
    },
    "candidate_test.listening_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.listening_grade = null;
      } else {
        this.candidate_test.listening_grade = this.getGrade(newScore.code);
      }
    },
    "candidate_test.reading_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.reading_grade = null;
      } else {
        this.candidate_test.reading_grade = this.getGrade(newScore.code);
      }
    },
    "candidate_test.writing_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.writing_grade = null;
      } else {
        this.candidate_test.writing_grade = this.getGrade(newScore.code);
      }
    },
    "candidate_test.speaking_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.speaking_grade = null;
      } else {
        this.candidate_test.speaking_grade = this.getGrade(newScore.code);
      }
    },
    "candidate_test.academic_reading_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.academic_reading_grade = null;
      } else {
        this.candidate_test.academic_reading_grade = this.getGrade(
          newScore.code
        );
      }
    },
    "candidate_test.general_reading_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.general_reading_grade = null;
      } else {
        this.candidate_test.general_reading_grade = this.getGrade(
          newScore.code
        );
      }
    },
    "candidate_test.ielts_listening_score"(newScore) {
      if (newScore === null || typeof newScore === "undefined") {
        this.candidate_test.ielts_listening_grade = null;
      } else {
        this.candidate_test.ielts_listening_grade = this.getGrade(
          newScore.code
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "getLanguageExam",
      "getLanguageExamScores",
      "getCandidateId",
      "getCandidateTestScores",
      "getLocationCountryInfo",
      "getCourseTestTypes",
      "getMultiFormEdited",
      "locationLanguage"
    ]),
    options() {
      return {
        countryList:
          this.getLocationCountryInfo.map((item) => ({
            code: item.country_id,
            label: item.country_name,
          })) || [],
        languageExam: this.getLanguageTest || [],
        listeningScores: this.getScores(1),
        readingScores: this.getScores(2),
        speakingScores: this.getScores(3),
        writingScores: this.getScores(4),
        academicReadingScores: this.getScores(5),
        generalReadingScores: this.getScores(6),
        ieltsListeningScores: this.getScores(7),
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        testTaken:[
          {id: 1, label:"Official Exam Test Result"},
          {id: 2, label: "Assessment or Evaluation Test"},
          {id: 3, label: "Practice/Mock Exam or Test"},
        ],
        courseTestType: this.getCourseTestTypes.map((item) => ({
          code: item.course_test_type_id,
          label: item.course_test_type
        })) || [],
        languageList: this.locationLanguage
      };
    },
    cardError() {
      if (Object.keys(this.candidate_test).length > 0) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isCandidateScore() {
      return this.getCandidateTestScores && this.getCandidateTestScores.length;
    },
    isOET() {
      return this.payload?.language_exam == 1 || false;
    },
    isIelts() {
      return this.payload?.language_exam == 2 || false;
    },
    getLanguageTest(){
      return this.getLanguageExam.map(val => ({
        code: val?.test_id,
        label: val?.test_acronym && val?.test_name
          ? `${val.test_acronym}-${val.test_name}`
          : val?.test_name,
        }));
    },
  },
  methods: {
    ...mapActions([
      "fetchLanguageExam",
      "fetchLanguageExamScores",
      "locationCountryInfo",
      "showToast",
      "createCandidateTestScore",
      "fetchCandidateTestScores",
      "fetchCourseTestTypes",
      "multiFormEdited",
      "fetchLocationLanguage"
    ]),

    handleChangeSelect(name, value) {
      Vue.set(this.candidate_test, name, value);
      if (name === "test_type") {
        this.payload = {
        ...this.payload,
        [name]: value ? value.label : null,
      };
      }
      else {
        this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      }
      if (name === "language_exam" && this.payload.language_exam) {
        this.fetchLanguageExamScores(this.payload.language_exam);
      }
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.candidate_test, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.languageScore.errors.clear;
        this.$refs.languageScore.reset();
      });
      this.$emit("tooltipHide");
      this.candidate_test = {};
      this.payload = {};
      this.cardHasError = "";
    },
    getScores(testSectionId) {
      return this.getLanguageExamScores
        .filter((score) => score.test_section_id === testSectionId)
        .map((score) => ({
          code: score.test_score_id,
          label:
            score.start_score && score.end_score
              ? `${score.start_score} - ${score.end_score}`
              : `${score.start_score}`,
        }));
    },
    getGrade(scoreId) {
      let val = this.getLanguageExamScores.filter(
        (score) => score.test_score_id === scoreId
      );
      return val[0].grade;
    },
    async onSubmit() {
      const isValid = await this.$refs.languageScore.validate();
      if (!isValid) {
        this.cardHasError = "Please Fill Mandatory Fields";
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Fill Mandatory Fields!",
        });
        return;
      }
      let finalPayload = {
        candidate_test: {
          candidate_uid: this.getCandidateId,
          test_id: this.payload.language_exam,
          country_obtained_id: this.payload.language_country,
          assessment_test: this.payload.assessment_test,
          date_obtained: this.payload.date_obtained,
          course_test_type_id: this.payload.course_test_type_id,
          language_id: this.payload.language_id,
          test_type: this.payload.test_type
        },
      };

      if (this.isOET) {
        finalPayload.section_data = {
          listening: {
            test_section_id: 1,
            test_score_id: this.payload.listening_score,
            score: this.payload.oet_listening_score,
          },
          reading: {
            test_section_id: 2,
            test_score_id: this.payload.reading_score,
            score: this.payload.oet_reading_score,
          },
          speaking: {
            test_section_id: 3,
            test_score_id: this.payload.speaking_score,
            score: this.payload.oet_speaking_score,
          },
          writing: {
            test_section_id: 4,
            test_score_id: this.payload.writing_score,
            score: this.payload.oet_writing_score,
          },
        };
      } else if (this.isIelts) {
        finalPayload.section_data = {
          academic_reading: {
            test_section_id: 5,
            test_score_id: this.payload.general_reading_score,
            score: this.payload.ielts_academic_reading_score,
          },
          general_reading: {
            test_section_id: 6,
            test_score_id: this.payload.general_reading_score,
            score: this.payload.ielts_general_reading_score,
          },
          listening: {
            test_section_id: 7,
            test_score_id: this.payload.ielts_listening_score,
            score: this.payload.ielts_listening_scores,
          },
        };
      }
      await this.createCandidateTestScore(finalPayload).then((res) => {
        if (res.status == 200) {
          this.showToast({
            class: "bg-success text-white",
            message: "Scores Added Successfully!",
          });
          this.fetchCandidateTestScores();
          this.clearFields();
        }
      });
    },
    routeToHome() {
      if (Object.values(this.candidate_test).length > 0) {
        this.cardHasError = "Please Save or Delete";
      } else {
        this.$router.push("/home");
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.candidate_test, name, value);
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    handleInput(name, value) {
      Vue.set(this.candidate_test, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
    },
    toggleInnerTable(index) {
      this.$set(this.showInnerTable, index, !this.showInnerTable[index]);
    }
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    this.fetchLanguageExam();
    this.locationCountryInfo();
    this.fetchCandidateTestScores();
    this.fetchCourseTestTypes();
    this.fetchLocationLanguage();
  },
};
</script>
