<template>
  <div class="candidate-profile">
    <div class="NonMedicalInformation">
      <div class="cardError">
        <div
          v-if="cardError"
          class="pb-1 cardError"
          :class="cardHasError ? 'card-error-msg' : ''"
        >
          {{ cardHasError }}
        </div>
      </div>
      <CCard :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <CRow>
                    <CCol md="8">
                      <h5 class="pb-3 main-title">
                        Languages<span class="h6"></span>
                          ( obtained or studying for )[{{
                            getCandidateTestScores.length || "0"
                          }}
                          record{{
                            getCandidateTestScores.length > 1 ? "s" : ""
                          }}]</span
                        >
                      </h5>
                    </CCol>
                    <CCol md="4">
                      <div class="mr-2 d-flex justify-content-end">
                        <CButton
                          class="mr-2 btn-primary small"
                          style="height: 28px"
                          v-if="
                            !isNewNonMedicalQualification &&
                            !getMultiFormEdited['languageQualification'] &&
                            !edit_non_medical_id
                          "
                          @click="showNonMedicalQualificationInfo"
                          v-c-tooltip="'Click to Add new record!'"
                          >Add Exam/Test Result</CButton
                        >

                        <CButton
                          class="mr-2 btn-primary small"
                          style="height: 28px"
                          @click="uploadInfo"
                          v-if="edit_non_medical_id"
                          v-c-tooltip="'Click to update the record!'"
                          >Update</CButton
                        >
                        <button
                          name="cancel"
                          v-if="
                            isNewNonMedicalQualification &&
                            !getMultiFormEdited['languageQualification']
                          "
                          class="hand-icon action-button"
                          v-c-tooltip="'Cancel'"
                          @click="resetFields()"
                        >
                          <CIcon
                            name="cil-x-circle"
                            style="margin-right: 20px"
                          />
                        </button>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
              <div
                class="border-dotted-bottom mb-3"
                v-if="
                  isNewNonMedicalQualification ||
                  edit_non_medical_id ||
                  getMultiFormEdited['languageQualification']
                "
              >
                <h6 class="text-primary">
                  Add Exam/Test - please record ALL your assessments and
                  exam/test results here
                </h6>
                <ValidationObserver
                  ref="languageQualification"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    id="languageQualification"
                    @submit.prevent="handleSubmit()"
                  >
                    <fieldset :disabled="isDisabled">
                      <CRow>
                        <CCol md="6">
                          <CRow class="d-flex align-items-center mb-3">
                            <label
                              name="lbl_language"
                              class="required col-lg-12 col-md-12"
                              >Language</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="language_id"
                                  :value="profile.language_id"
                                  @input="handleChangeSelect"
                                  :options="
                                    options && options['languages']
                                      ? options['languages']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="false"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>

                        <CCol md="6">
                          <CRow class="row mb-3">
                            <label
                              name="language_exam_label"
                              class="col-lg-12 col-md-12 required"
                              >Exam/Test Name</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <Select
                                  name="language_exam"
                                  :value="profile.language_exam"
                                  @change="handleChangeSelectExam"
                                  :options="
                                    (options && options['languageExam']) || []
                                  "
                                  :clearable="false"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </CCol>
                      </CRow>
                      <div class="highlight-bg" v-if="payload.language_exam">
                        <CRow>
                          <CCol md="6">
                            <CRow class="d-flex align-items-center mb-3">
                              <label
                                name="test_taken"
                                class="col-lg-12 col-md-12"
                                >Required Level/Grade</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <Select
                                  name="required_score_id"
                                  :value="profile.required_score_id"
                                  :options="
                                    (options && options['testScores']) || []
                                  "
                                  @input="handleChangeSelectCustom"
                                />
                              </div>
                            </CRow>
                          </CCol>
                        </CRow>
                        <div
                          v-if="false&&getResultFields(payload.language_exam)?.length"
                        >
                          <LanguageTest
                            ref="languageTest"
                            :sections="
                              getSectionByTestId(payload.language_exam)
                            "
                            :language_exam="profile.language_exam"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </ValidationObserver>
                <div class="d-flex justify-content-end my-3">
                  <CButton
                    class="mr-2 btn-primary small"
                    style="height: 28px"
                    v-if="isEdited"
                    @click="uploadInfo"
                    v-c-tooltip="'Click to Save record!'"
                    >Save</CButton
                  >
                  <CButton
                    v-if="getMultiFormEdited['languageQualification']"
                    color="primary"
                    variant="outline"
                    v-c-tooltip="'Cancel'"
                    size="sm"
                    @click="resetFields()"
                    >Cancel</CButton
                  >
                </div>
              </div>
            </div>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <th>Language</th>
                <th>Test Name</th>
                <th>Latest Level</th>
                <th>Required Level</th>
                <th>Predicated Pass Date</th>
                <th>Exam Booked</th>
                <th></th>
              </thead>
              <tbody class="multi-records">
                <template v-for="(scores, index) in getCandidateTestScores">
                  <tr>
                    <td>{{ scores.language }}</td>
                    <td>{{ scores.test_acronym }}</td>
                    <td>
                      <span class="text-nowrap">
                        {{
                          getResultTestScore(
                            scores.current_score,
                            scores.test_id
                          ) | grade
                        }}
                        ({{
                          getResultTestScore(
                            scores.current_score,
                            scores.test_id
                          ) | cefr
                        }})</span
                      >
                    </td>
                    <td>
                      {{ requiredScore(scores._rawData) | grade }}({{
                        requiredScore(scores._rawData) | cefr
                      }})
                    </td>
                    <td>{{ scores.predicted_date | dateFormat }}</td>
                    <td>{{ scores.official_test_booked | dateFormat }}</td>
                    <td>
                      <div
                        class="text-primary h4"
                        v-c-tooltip="{
                          content: 'Expand Scores',
                        }"
                      >
                        <em
                          :class="
                            !collapsedIndex.includes(index)
                              ? 'fa-solid fa-circle-plus'
                              : 'fa-solid fa-circle-minus'
                          "
                          @click="toggleInnerTable(index)"
                        ></em>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="collapsedIndex.includes(index)" class="top-border">
                    <td colspan="8" class="p-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Reading</th>
                            <th>Listening</th>
                            <th>Speaking</th>
                            <th>Writing</th>
                            <th>Result</th>
                            <th>CEFR</th>
                            <th>
                              <CButton
                                color="primary"
                                size="sm"
                                variant="outline"
                                v-c-tooltip="'Add New Test Score'"
                                class="text-nowrap"
                                @click="openAddNewTestScore(scores, index)"
                                >Add Result</CButton
                              >
                            </th>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                {
                                  skills: skill,
                                  date_obtained,
                                  test_type,
                                  avg,
                                },
                                subIndex
                              ) in scores.skills"
                              class="top-border"
                            >
                              <td>{{ date_obtained | dateConvert }}</td>
                              <td>{{ test_type }}</td>
                              <td>
                                <span
                                  class="text-nowrap"
                                  v-if="extractExamScores(skill, 'Reading')"
                                >
                                  <span v-if="scores.test_id != 2"
                                    >{{
                                      extractExamScores(skill, "Reading")
                                        | score
                                    }}
                                    -
                                  </span>
                                  <span
                                    :class="{
                                      'text-primary grade-text':
                                        scores.test_id != 2,
                                    }"
                                    >{{
                                      extractExamScores(skill, "Reading")
                                        | grade
                                    }}({{
                                      extractExamScores(skill, "Reading")
                                        | cefr
                                    }})</span
                                  >
                                </span>
                                <span v-else>--</span>
                              </td>
                              <td>
                                <span
                                  class="text-nowrap"
                                  v-if="extractExamScores(skill, 'Listening')"
                                >
                                  <span v-if="scores.test_id != 2"
                                    >{{
                                      extractExamScores(skill, "Listening")
                                        | score
                                    }}
                                    -
                                  </span>

                                  <span
                                    :class="{
                                      'text-primary grade-text':
                                        scores.test_id != 2,
                                    }"
                                    >{{
                                      extractExamScores(skill, "Listening")
                                        | grade
                                    }}({{
                                      extractExamScores(skill, "Listening")
                                        | cefr
                                    }})</span
                                  >
                                </span>
                                <span v-else>--</span>
                              </td>
                              <td>
                                <span
                                  class="text-nowrap"
                                  v-if="extractExamScores(skill, 'Speaking')"
                                >
                                  <span v-if="scores.test_id != 2"
                                    >{{
                                      extractExamScores(skill, "Speaking")
                                        | score
                                    }}
                                    -
                                  </span>
                                  <span
                                    :class="{
                                      'text-primary grade-text':
                                        scores.test_id != 2,
                                    }"
                                    >{{
                                      extractExamScores(skill, "Speaking")
                                        | grade
                                    }}({{
                                      extractExamScores(skill, "Speaking")
                                        | cefr
                                    }})</span
                                  >
                                </span>
                                <span v-else>--</span>
                              </td>
                              <td>
                                <span
                                  class="text-nowrap"
                                  v-if="extractExamScores(skill, 'Writing')"
                                >
                                  <span v-if="scores.test_id != 2"
                                    >{{
                                      extractExamScores(skill, "Writing")
                                        | score
                                    }}
                                    -
                                  </span>
                                  <span
                                    :class="{
                                      'text-primary grade-text':
                                        scores.test_id != 2,
                                    }"
                                    >{{
                                      extractExamScores(skill, "Writing")
                                        | grade
                                    }}({{
                                      extractExamScores(skill, "Writing")
                                        | cefr
                                    }})</span
                                  >
                                </span>
                                <span v-else>--</span>
                              </td>
                              <td>{{ avg }}</td>
                              <td>
                                {{
                                  getResultTestScore(avg, scores.test_id) | cefr
                                }}
                              </td>
                              <td>
                                <CButton
                                  class="icon icon-edit mb-2"
                                  @click="editTestScore(skill)"
                                  v-c-tooltip="'Edit this Test Score'"
                                >
                                </CButton>
                                <CButton
                                  class="mb-2"
                                  @click="deleteTestScore(skill)"
                                  v-c-tooltip="'Delete Score'"
                                >
                                  <i class="fas fa-trash-alt" />
                                </CButton>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </template>
                <td colspan="8" v-if="getCandidateTestScores.length == 0">
                  <p class="text-muted text-center">No Data Found</p>
                </td>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />

    <CModal
      v-if="testScoreModal.isShowPopup"
      :show.sync="testScoreModal.isShowPopup"
      :close-on-backdrop="false"
      :color="testScoreModal.modalColor"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{ testScoreModal.modalTitle }}</h6>
        <CButtonClose
          @click="testScoreModalCallBack(false)"
          class="text-black"
        />
      </template>
      <template #footer>
        <CButton
          class="ml-2"
          color="primary"
          @click="testScoreModalCallBack(true)"
          >Submit</CButton
        >
        <CButton
          class="ml-2"
          color="primary"
          variant="outline"
          @click="testScoreModalCallBack(false)"
          >Cancel</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="testScoreForm" v-slot="{ handleSubmit }">
          <form id="testScoreForm" @submit.prevent="handleSubmit()">
            <LanguageTest
              ref="addTestScore"
              :sections="testScoreModal.sections"
              :language_exam="testScoreModal.language_exam"
              :testInfo="testScoreModal.skills"
              :isEdit="testScoreModal.isEdit"
              :isAddTest="!testScoreModal.isEdit"
              :sectionIndex="sectionIndex"
            />
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>

<script>
import * as _ from "lodash";
import m from "moment";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import { extend } from "vee-validate";
import { required, min_value, max_value } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("min_value", (value, args) => {
  const min = args?.min;
  if (min <= value) {
    return true;
  }
  return `Min Value is ${min}`;
});
extend("max_value", (value, args) => {
  const max = args?.max;
  if (max >= value) {
    return true;
  }
  return `Max Value is ${max}`;
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
import Vue from "vue";
import LanguageTest from "@/components/reusable/LanguageTest.vue";

export default {
  name: "LanguageQualificationInfoTalentFind",
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Modal,
    LanguageTest,
  },
  data() {
    return {
      collapsedIndex: [],
      assessment_test: {},
      isDisabled: false,
      profile: {},
      NonMedicalInfo: {},
      nonMedicalInfoUpload: false,
      id: 0,
      non_medical_qual_id: null,
      isNewNonMedicalQualification: false,
      edit_non_medical_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      cardHasError: "",
      other_qualification: false,
      payload: {},
      testScoreModal: {
        isShowPopup: false,
        modalColor: "primary",
        modalTitle: "Add New Test Score",
        data: null,
      },
      sectionIndex: null,
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "scoreFilteredByNonMedicalQualName",
      "getNonMedicalQual",
      "getCandidateNonMedicalQuals",
      "getNonMedicalQualforNonClinic",
      "uploaded",
      "getLanguageExam",
      "getLanguageExamScores",
      "getCandidateTestScores",
      "getCandidateId",
      "getTestSections",
      "getTestScoresList",
      "getOverallResult",
      "getTestExamTypes",
    ]),
    isEdited() {
      return this.getMultiFormEdited?.languageQualification;
    },
    options() {
      return {
        languages: this.locationLanguageReOrder || [],
        languageExam: this.getLanguageTest || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        assessmentTest: [
          { code: 1, label: "Take the FREE test now" },
          { code: 2, label: "Take the FREE test later" },
          { code: 3, label: "I don’t want to take the test" },
        ],
        testScores: this.testScores.map((v) => ({
          code: v.test_score_id,
          label: v.grade,
        })),

        score: this.scoreFilteredByNonMedicalQualName || [],
      };
    },
    testScores() {
      return (
        this.getTestScoresList
          .filter((v) => v.test_id === this.payload?.language_exam)
          .sort((a, b) => b.start_score - a.start_score) || []
      );
    },

    getLanguageTest() {
      return this.profile.language_id?.code
        ? this.getLanguageExam
            .map((val) => ({
              code: val?.test_id,
              label:
                val?.test_acronym && val?.test_name
                  ? `${val.test_acronym}-${val.test_name}`
                  : val?.test_name,
              test_acronym: val?.test_acronym,
            }))
            .filter((v) =>
              this.profile.language_id?.code === 1 ? true : v.code === 4
            )
        : [];
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage &&
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.languageQualification
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isTOFEL() {
      return this.profile?.language_exam?.code === 3 || false;
    },
    sections() {
      const orderBy = ["Listening", "Writing", "Reading", "Speaking"];
      return this.getTestSections
        .map((v) => ({
          test_id: v.test_id,
          name: _.snakeCase(v.section_name),
          label: v.section_name,
          section_id: v.test_section_id,
        }))
        .sort((a, b) => orderBy.indexOf(a.label) - orderBy.indexOf(b.label));
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateNonMedicalQuals",
      "uploadCandidateNonMedicalInfo",
      "fetchScoreByNonMedicalQual",
      "fetchNonMedicalQualNames",
      "showToast",
      "updateNonMedicalInfo",
      "fetchLanguageExam",
      "fetchCandidateTestScores",
      "fetchLanguageExamScores",
      "createCandidateTestScore",
      "fetchTestSections",
      "showLoader",
      "hideLoader",
      "updateTestSection",
      "deleteCandidateTest",
      "fetchTestScores",
      "fetchTestExamTypes",
    ]),
    handleChangeSelectExam(name, value) {
      const test_id = value?.code;
      const hasRecord = this.getCandidateTestScores?.find(
        (v) => v.test_id == test_id
      );
      if (!hasRecord) {
        this.handleChangeSelect(name, value);
        if(this.profile.required_score_id){
          const data = this.getDataFromProfile();
          this.openNewTestScore(data);
        }
        return;
      }
      this.openAddNewTestScore(hasRecord);
    },
    openNewTestScore(data) {
      const { test_id, language_exam } = data;
      // this.sectionIndex = index;
      this.testScoreModal.modalTitle = `Add New Test Score`;
      this.testScoreModal.sections = this.getSectionByTestId(test_id);
      this.testScoreModal.language_exam = {
        code: test_id,
        label: language_exam
      };
      this.testScoreModal.data = data;

      this.testScoreModal.isShowPopup = true;
      this.testScoreModal.isEdit = false;
    },
    handleChangeRadioCustom(name, value) {
      Vue.set(this.assessment_test, name, value);
    },
    getResultTestScore(avg, test_id) {
      const res = this.getOverallResult({ test_id, score: avg });

      return res;
    },
    getTestScoreInfo(data) {
      const test_id = data?.test_id;
      const score = data?.score || 0;
      if (test_id) {
        return this.getOverallResult({ score, test_id });
      }
      return null;
    },
    editTestScore(skill) {
      this.testScoreModal.isEdit = true;
      const { test, test_id } = skill[0]._rawData;
      this.testScoreModal.modalTitle = `Edit Test Score`;
      this.testScoreModal.sections = this.getSectionByTestId(test_id);
      this.testScoreModal.language_exam = {
        code: test_id,
        label: test.test_name,
      };
      this.testScoreModal.skills = skill.map((v) => v._rawData);
      this.testScoreModal.data = skill[0]._rawData;
      this.testScoreModal.isShowPopup = true;
    },
    openAddNewTestScore(scores, index) {
      const { language_exam, test_id } = scores;
      this.sectionIndex = index;
      this.testScoreModal.modalTitle = `Add New Test Score`;
      this.testScoreModal.sections = this.getSectionByTestId(test_id);
      this.testScoreModal.language_exam = {
        code: test_id,
        label: language_exam,
      };
      this.testScoreModal.data = scores._rawData;

      this.testScoreModal.isShowPopup = true;
      this.testScoreModal.isEdit = false;
    },
    getSectionByTestId(examCode) {
      return this.sections.filter((v) => v.test_id === examCode) || [];
    },
    extractExamScores(skill, sectionName) {
      let _sectionName = sectionName;
      const test = skill.find(({ skills }) => skills === _sectionName) || null;
      const test_id = test?._rawData?.test_id;
      const score = test_id != 2 ? test.score : test.grade;
      return test
        ? {
            score: test.score,
            grade: test.grade,
            test_id: test?._rawData?.test_id,
            cefr_grade: this.getResultTestScore(score, test_id)?.cefr_grade,
          }
        : null;
    },
    toggleInnerTable(index) {
      if (this.collapsedIndex.includes(index)) {
        const _index = this.collapsedIndex.findIndex((v) => v == index);
        if (_index >= 0) this.collapsedIndex.splice(_index, 1);
      } else {
        this.collapsedIndex.push(index);
      }
    },
    getResultFields(examCode) {
      const list = this.sections.filter((v) => v.test_id === examCode);

      let matrix = [],
        i,
        k;

      for (i = 0, k = -1; i < list.length; i++) {
        if (i % 2 === 0) {
          k++;
          matrix[k] = [];
        }
        const data = list[i];
        const label = { value: data.label, type: "label" };
        const name = { value: data.name, type: "name" };
        const grade = { value: `${data.name}_grade`, type: "grade" };
        const res = [label, name, grade];
        matrix[k] = [...matrix[k], ...res];
      }
      return matrix;
    },
    handleChangeSelectCustom(name, value) {
      this.handleChangeSelect(name, value);
      if(this.profile.language_exam){
        const test_id = this.profile.language_exam?.code;
        const hasRecord = this.getCandidateTestScores?.find(
        (v) => v.test_id == test_id
      );
      if(!hasRecord) {
        const data = this.getDataFromProfile();
        this.openNewTestScore(data);
      }
    }
    },
    getDataFromProfile(){
      return {
        test_id: this.profile?.language_exam?.code,
        language_exam: this.profile?.language_exam?.label,
        language_id: this.profile?.language_id?.code,
        required_score_id: this.profile?.required_score_id?.code
      }
    },
    getGrade(score, section_id, test_id) {
      let val = this.getTestScoresList.find(
        (v) =>
          v.test_id == test_id &&
          v.test_section_id == section_id &&
          (v.end_score ? v.end_score >= score : v.start_score == score) &&
          v.start_score <= score
      );
      return val || null;
    },

    async uploadInfo() {
      const isValid = await this.$refs.languageQualification.validate();
      if (!isValid) {
        this.cardHasError = "Please Fill Mandatory Fields";
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Fill Mandatory Fields!",
        });
        return;
      }
      const sectionPayload = this.$refs?.languageTest?.getPayload();

      if(!sectionPayload) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Add Test Scores!",
        });
        const data = this.getDataFromProfile();
        this.openNewTestScore(data);
        return;
      }
      const {
        test_type,
        official_test_booked,
        assessment_test,
        date_obtained,
        section_data,
      } = sectionPayload;

      let finalPayload = {
        candidate_test: {
          candidate_uid: this.getCandidateId,
          test_id: this.payload.language_exam,
          assessment_test,
          date_obtained,
          course_test_type_id: this.payload.course_test_type_id,
          language_id: this.payload.language_id,
          test_type,
          official_test_booked,
          required_score_id: this.payload?.required_score_id || null,
        },
      };

      finalPayload = {
        ...finalPayload,
        section_data,
      };
      this.createCandidateTest(finalPayload);
      this.collapsedIndex = [];
    },
    showNonMedicalQualificationInfo() {
      this.isNewNonMedicalQualification = true;
    },
    resetFields() {
      this.profile = {};
      this.payload = {};
      this.edit_non_medical_id = null;
      this.$emit("tooltipHide");
      this.isNewNonMedicalQualification = false;
      this.clearFields();
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs?.languageQualification?.reset();
        this.$refs?.languageQualification?.errors.clear;
      });
      this.cardHasError = "";
      this.multiFormEdited({ languageQualification: false });
      this.multiFormEdited({ showError: false });
    },
    async testScoreModalCallBack_back(action) {
      if (action) {
        const isValid = await this.$refs.testScoreForm.validate();
        if (!isValid) {
          this.cardHasError = "Please Fill Mandatory Fields";
          this.showToast({
            class: "bg-danger text-white",
            message: "Please Fill Mandatory Fields!",
          });
          return;
        }
        const data = this.testScoreModal.data;
        const {
          test_type,
          section_data,
          date_obtained,
          assessment_test,
          official_test_booked,
        } =  this.$refs?.addTestScore?.getPayload();

        let finalPayload = {
          candidate_test: {
            candidate_uid: this.getCandidateId,
            test_id: data.test_id,
            assessment_test: assessment_test,
            course_test_type_id: data.course_test_type_id,
            language_id: data.language_id,
            test_type: test_type,
            official_test_booked,
            date_obtained,
            required_score_id: data?.required_score_id,
          },
        };

        finalPayload = {
          ...finalPayload,
          section_data,
        };
        let appendAction = [];
        if (this.testScoreModal.isEdit) {
          const test_id = data.test_id;
          appendAction = [this.updateTestSection({ test_id, section_data })];
        } else {
          appendAction = [this.createCandidateTest(finalPayload)];
        }
        Promise.all(appendAction).then((res) => {
          if (this.testScoreModal.isEdit) {
            this.fetchCandidateTestScores();
          }
          this.testScoreModal.isShowPopup = false;
          this.testScoreModal.data = null;
          this.testScoreModal.sections = null;
          this.testScoreModal.language_exam = null;
          this.collapsedIndex = [];
        });
      } else {
        this.testScoreModal.isShowPopup = false;
        this.testScoreModal.data = null;
        this.testScoreModal.sections = null;
        this.testScoreModal.language_exam = null;
      }
    },

    async testScoreModalCallBack(action) {
      if (action) {
        const isValid = await this.$refs.testScoreForm.validate();
        if (!isValid) {
          this.cardHasError = "Please Fill Mandatory Fields";
          this.showToast({
            class: "bg-danger text-white",
            message: "Please Fill Mandatory Fields!",
          });
          return;
        }
        const data = this.testScoreModal.data;
        const {
          test_type,
          section_data,
          date_obtained,
          assessment_test,
          official_test_booked,
        } =  this.$refs?.addTestScore?.getPayload();

        let finalPayload = {
          candidate_test: {
            candidate_uid: this.getCandidateId,
            test_id: data.test_id,
            assessment_test: assessment_test,
            language_id: data.language_id,
            test_type: test_type,
            official_test_booked,
            date_obtained,
            required_score_id: data?.required_score_id,
          },
        };
        if(data.course_test_type_id){
          finalPayload.candidate_test.course_test_type_id = data.course_test_type;
        }

        finalPayload = {
          ...finalPayload,
          section_data,
        };
        let appendAction = [];
        if (this.testScoreModal.isEdit) {
          const test_id = data.test_id;
          appendAction = [this.updateTestSection({ test_id, section_data })];
        } else {
          appendAction = [this.createCandidateTest(finalPayload)];
        }
        Promise.all(appendAction).then((res) => {
          if (this.testScoreModal.isEdit) {
            this.fetchCandidateTestScores();
          }
          this.testScoreModal.isShowPopup = false;
          this.testScoreModal.data = null;
          this.testScoreModal.sections = null;
          this.testScoreModal.language_exam = null;
          this.collapsedIndex = [];
        });
      } else {
        this.testScoreModal.isShowPopup = false;
        this.testScoreModal.data = null;
        this.testScoreModal.sections = null;
        this.testScoreModal.language_exam = null;
      }
    },

    async createCandidateTest(finalPayload) {
      if (!finalPayload?.section_data?.length) {
        this.showToast({
          class: "bg-success text-white",
          message: "Scores Empty!",
        });
        return;
      }
      await this.createCandidateTestScore(finalPayload).then((res) => {
        if (res.status == 200) {
          this.showToast({
            class: "bg-success text-white",
            message: "Scores Added Successfully!",
          });
          this.fetchCandidateTestScores();
          this.profile = {};
          this.payload = {};
          this.assessment_test = {};
          this.clearFields();
          this.isNewNonMedicalQualification = false;
        }
      });
      return;
    },
    deleteTestScore(skill) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this Test ?`;
      this.deleteConfirmModal.data = skill;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        let appendAction = [];
        const { data } = this.deleteConfirmModal;

        data.forEach((element) => {
          const { candidate_test_id } = element._rawData;
          appendAction.push(this.deleteCandidateTest(candidate_test_id));
        });
        Promise.all(appendAction).then((res) => {
          this.fetchCandidateTestScores();
        });
      }
      this.resetFields();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
    requiredScore(data) {
      return data?.required_score_id
        ? this.getTestScoresList.find(
            (v) => v.test_score_id === data?.required_score_id
          )
        : null;
    },
  },
  mounted() {
    let appendAction = [];
    if (!this.getNonMedicalQual?.length)
      appendAction.push(this.fetchNonMedicalQualNames());
    if (!this.getTestScoresList.length)
      appendAction.push(this.fetchTestScores());
    if (!this.getTestExamTypes.length)
      appendAction.push(this.fetchTestExamTypes());
    appendAction.push(this.fetchLanguageExam());
    appendAction.push(this.fetchCandidateTestScores());
    appendAction.push(this.fetchTestSections());
    this.showLoader();
    Promise.all(appendAction).then((res) => {
      this.hideLoader();
    });
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.nonMedicalInfoUpload) {
          this.fetchCandidateNonMedicalQuals();
          this.nonMedicalInfoUpload = false;
        }
      }
    },
  },
  filters: {
    grade(data) {
      return data?.grade || "";
    },
    cefr(data) {
      return data?.cefr_grade || "--";
    },
    dateConvert(data) {
      if (data) {
        const toDay = m();
        const givenDate = m(data);
        const months = toDay.diff(givenDate, "months");
        const isExpired = months > 24;
        let res = givenDate.format("MMM YYYY");
        res = isExpired ? `${res} (Expired)` : res;
        return res;
      }
      return "--";
    },
    dateFormat(date) {
      return date ? m(date, "YYYY-MM-DD").format("DD-MMM-YYYY") : "N/A";
    },
    score(data) {
      return data?.score || "--";
    },
    grade(data) {
      return data?.grade || "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.score-input {
  min-width: 100px;
}
.grade-text {
  font-weight: 500;
}
.highlight-bg {
  background: #f8d8d84a;
  border-radius: 6px;
  padding: 5px;
  margin: -5px;
}
</style>
