<template>
  <div>
    <div class="ExperienceInformation">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <h5 class="pb-3 main-title">
                    Employment Experience
                    <span class="h6">
                      [{{ candidateExperiences.length || "0" }} record{{
                        candidateExperiences.length > 1 ? "s" : ""
                      }}]</span
                    >
                  </h5>
                </div>
              </div>
              <ValidationObserver
                ref="ExperienceInfos"
                v-slot="{ handleSubmit }"
              >
                <form id="ExperienceInfos" @submit.prevent="handleSubmit()">
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="organisation_type"
                            class="required col-lg-12 col-md-12"
                            >Type of Healthcare Facility</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="org_type_id"
                                :value="profile.org_type_id"
                                :options="
                                  options && options['organisation_type']
                                    ? options['organisation_type']
                                    : []
                                "
                                :multiple="false"
                                :clearable="false"
                                @input="handleChangeSelect"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_organisation_nature"
                            class="required col-lg-12 col-md-12"
                            >Is this a Private or Government facility ?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <RadioButton
                                name="organisation_nature"
                                :value="profile.organisation_nature"
                                :options="
                                  options && options['organisation_nature']
                                    ? options['organisation_nature']
                                    : []
                                "
                                :error="errors[0]"
                                @change="handleChangeRadio"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="facility_type"
                            class="required col-lg-12 col-md-12"
                            >Facility Details</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="organisation_additional_info"
                                :value="profile['organisation_additional_info']"
                                :options="
                                  options && options['organisation_additional_info']
                                    ? options['organisation_additional_info']
                                    : []
                                "
                                :multiple="false"
                                :clearable="false"
                                @input="handleChangeSelect"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="TypeisOther" lg="6">
                        <CRow class="row mb-3">
                          <label class="required col-lg-12 col-md-12"
                            >What Type of Facility Is This?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="organisation_additional_info_other"
                                :value="profile.organisation_additional_info_other"
                                @input="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_score"
                            class="required col-lg-12 col-md-12"
                          >
                            Location of Facility</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="organisation_location_id"
                                :value="profile.organisation_location_id"
                                :options="
                                  options && options['country']
                                    ? options['country']
                                    : []
                                "
                                :clearable="false"
                                @input="handleChangeSelect"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_organisation_bed_count"
                            class="col-lg-12 col-md-12"
                            >Bed Count / Size of Facility</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="organisation_bedcount"
                              :value="profile.organisation_bedcount"
                              type="Number"
                              @input="handleInput"
                              :onkeydown="restrictedKeys"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_direct_report_count"
                            class="col-lg-12 col-md-12"
                            >Number of Staff Reporting Directly to You</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="direct_reports_count"
                              :value="profile.direct_reports_count"
                              type="Number"
                              @input="handleInput"
                              :onkeydown="restrictedKeys"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_is_current_org"
                            class="required col-lg-12 col-md-12"
                            >Is this your Current Employment ?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <RadioButton
                                name="is_current_org"
                                :value="profile.is_current_org"
                                :options="
                                  options && options['boolean']
                                    ? options['boolean']
                                    : []
                                "
                                :error="errors[0]"
                                @change="handleChangeRadio"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org != null" md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_start_month_year"
                            class="required col-lg-12 col-md-12"
                            >Start Month / year</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider name="dob" v-slot="{ errors }">
                              <TextInput
                                hidden
                                name="dob"
                                :value="profile.dob"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              rules="QualifiedDate:@dob|date_validate|required|startBefore:@end_month_year"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="start_month_year"
                                :value="profile.start_month_year"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org == false" md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_end_month_year"
                            class="required col-lg-12 col-md-12"
                            >End Month / year</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider name="dob" v-slot="{ errors }">
                              <TextInput
                                hidden
                                name="dob"
                                :value="profile.dob"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              name="end_month_year"
                              rules="QualifiedDate:@dob|date_validate|required|startAfter:@start_month_year"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="end_month_year"
                                :value="profile.end_month_year"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="profile.is_current_org == false">
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_nursing_level"
                            class="col-lg-12 col-md-12 required"
                            >Level</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="exp_level_id"
                                :value="profile.exp_level_id"
                                :options="
                                  options && options['candidateExpLevel']
                                    ? options['candidateExpLevel']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                                :showTooltip="true"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_current_job"
                            class="col-lg-12 col-md-12"
                            >Job Title / Role</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="designation"
                              :value="profile.designation"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6"></CCol>
                      <CCol md="6">
                        <div class="float-right mb-2">
                          <CButton
                            v-if="
                              !edit_experience_id &&
                              getMultiFormEdited['ExperienceInfos']
                            "
                            class="btn-primary small mr-2"
                            style="height: 28px"
                            @click="uploadExperienceInfo"
                            v-c-tooltip="
                              'Click to Add multiple records!'
                            "
                            >Add</CButton
                          >
                          <CButton
                            class="btn-primary small mr-1"
                            style="height: 28px"
                            @click="uploadExperienceInfo"
                            v-if="edit_experience_id"
                            v-c-tooltip="
                              'Click to update the record!'
                            "
                            >Update</CButton
                          >
                          <button
                            name="cancel"
                            class="hand-icon action-button"
                            v-c-tooltip="'Cancel'"
                            v-if="
                              edit_experience_id ||
                              getMultiFormEdited['ExperienceInfos']
                            "
                            @click="resetFields(), clearFields()"
                          >
                            <CIcon name="cil-x-circle" style="margin-right: 20px" />
                          </button>
                        </div>
                      </CCol>
                    </CRow>
                    <div v-if="candidateExperiences" class="multi-records">
                      <div
                        class="top-border d-flex justify-content-between"
                        v-for="(data, index) in candidateExperiences"
                        :key="index"
                      >
                        <div class="ml-2 mt-1 record">
                          <div class="widget-content-actions hide-large-screen">
                            <CButton
                              class="icon icon-edit mr-2"
                              v-if="data && data.experience_id"
                              @click="editExperience(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon mr-1"
                              v-if="data && data.experience_id"
                              @click="deleteExperience(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                          <CRow
                            class="d-flex"
                            style="
                              justify-content: space-between;
                              margin-left: 5px;
                            "
                          >
                            <div class="table col col-4">
                              <p class="head">Type of Healthcare Facility</p>
                              <p class="data">{{ data.organisation_type
                                  ? data.organisation_type + ' ' 
                                    +'(' +  
                                      data.organisation_nature_label
                                    + ')'
                                  : "--"
                                }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Facility Details</p>
                              <p class="data">
                                {{ additionalInfo(data) || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Location of Facility</p>
                              <p class="data">
                                {{ data.organisation_location || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Bed Count / Size of Facility</p>
                              <p class="data">
                                {{ data.organisation_bedcount || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Number of Staff Reporting Directly to You</p>
                              <p class="data">
                                {{ data.direct_reports_count || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Current Employment</p>
                              <p class="data">
                                {{ data.is_current_employement || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Employment Period</p>
                              <p class="data">
                                {{ 
                                  data.is_current_org 
                                  ? formatDate(data.start_month, data.start_year)  + ' - ' + formatDate() + ' (Present)'
                                  : formatDate(data.start_month, data.start_year)  + ' - ' + formatDate(data.end_month, data.end_year)}}
                              </p>
                            </div>
                            <div v-if="data.is_current_org != null " class="table col col-4">
                              <p class="head">Experience in years</p>
                              <p class="data">
                                {{ 
                                  data.experience_in_years? data.experience_in_years + ' yrs ' : "0 yrs"
                                }}
                              </p>
                            </div>
                            <div v-if="data.is_current_org == false" class="table col col-4">
                              <p class="head">Level</p>
                              <p class="data">
                                {{ data.level_name || "--" }}
                              </p>
                            </div>
                            <div v-if="data.is_current_org == false" class="table col col-4">
                              <p class="head">Job Tittle / Role</p>
                              <p class="data">
                                {{ data.designation || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4"></div>
                          </CRow>
                        </div>
                        <div class="hide-small-screen">
                          <div
                            class="widget-content-actions d-flex flex-column justify-content-center"
                          >
                            <CButton
                              class="icon icon-edit mb-2"
                              v-if="data && data.experience_id"
                              @click="editExperience(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon"
                              v-if="data && data.experience_id"
                              @click="deleteExperience(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import m from "moment";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("startBefore", {
  params: ["end_month_year"],
  validate(value, { end_month_year }) {
    if (end_month_year) {
      let target_date = new Date(end_month_year);
      let input_date = new Date(value);
      if (input_date > target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given Start year should be lesser than End year!",
});
extend("startAfter", {
  params: ["start_month_year"],
  validate(value,  { start_month_year }) {
    if (start_month_year) {
      let target_date = new Date(start_month_year);
      let input_date = new Date(value);
      if (input_date < target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given End year should be greater than Start year!",
});


export default {
  name: "ExperienceInfo",
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      profile: {},
      experienceInfo: {},
      experienceUpload: false,
      delete_experience_id: null,
      edit_experience_id: null,
      confirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: [],
        data: null
      },
      deleteModal: false,
      currentConfirmModal: false,
      errors: [],
      cardHasError: "",
      other_additional_info: false,
      payload: {},
      restrictedKeys:
        "javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))",
      isFetchingOptions: true,
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "getCandidateExperiences",
      "getCandidateExpOrgTypes",
      "locationCountryInfo",
      "getOrgNature",
      "getOrgAdditionalInfo",
      "candidateLevelFilterByTypeCountry",
    ]),
    options() {
      return {
        organisation_type: this.getCandidateExpOrgTypes || [],
        country: this.locationCountryInfo || [],
        organisation_nature: this.getOrgNature || [],
        organisation_additional_info: this.getOrgAdditionalInfo || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        candidateExpLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.candidateTypeId,
            country_id: null,
          }) || [],
      };
    },
    TypeisOther() {
      return (
        this.profile?.organisation_additional_info?.label.includes(
          "Other"
        ) || false
      );
    },
    candidateExperiences() {
      if (Object.keys(this.candidateProfile).length) {
        this.profile.dob = this.candidateProfile.dob;
      }
      return (
        this.getCandidateExperiences?.length &&
        this.getCandidateExperiences.map(
          ({
            experience_id,
            organisation_type_id,
            organisation_nature,
            organisation_additional_info,
            organisation_additional_info_other,
            organisation_location_id,
            organisation_bedcount,
            direct_reports_count,
            is_current_org,
            start_month,
            start_year,
            end_year,
            end_month,
            designation,
            organisation_type,
            organisation_location,
            level_id,
            level      
          }) => {
            return {
              experience_id: experience_id !== null ? experience_id : "--",
              organisation_type_id: organisation_type_id
                ? organisation_type_id
                : null,
              organisation_type: organisation_type?.organisation_type
                ? organisation_type?.organisation_type
                : null,
              organisation_nature: organisation_nature
                ? organisation_nature
                : null,
              organisation_nature_label: organisation_nature
                ? _.startCase(organisation_nature)
                : null,
              organisation_location: organisation_location?.country_name
                ? _.startCase(organisation_location?.country_name)
                : null,
              organisation_additional_info_id: organisation_additional_info[0]
                ? organisation_additional_info[0]
                : null,
              organisation_additional_info: organisation_additional_info[0]
                ? _.startCase(organisation_additional_info[0]) + ' Hospital/Facility '
                : null ,
              organisation_additional_info_other: organisation_additional_info_other
                ? organisation_additional_info_other
                : null,
              organisation_location_id: organisation_location_id ? organisation_location_id : "--",
              organisation_bedcount: organisation_bedcount ? organisation_bedcount : "--",
              direct_reports_count: direct_reports_count ? direct_reports_count : "--",
              is_current_org: is_current_org !== null
                ? is_current_org
                : null,
              is_current_employement: is_current_org !== null  
                ? is_current_org != false
                  ? "Yes"
                  : "No"
                : "--",
              start_month: start_month 
                ? start_month 
                : null,
              start_year: start_year 
                ? start_year 
                : null,
              end_month: end_month 
                ? end_month 
                : null,
              end_year: end_year 
                ? end_year 
                : null,
              designation: designation !== null 
                ? designation 
                : null,
              level_id: level_id 
                ? level_id 
                : null,
              level_name: level?.candidate_level 
                ? level?.candidate_level
                : null,
              experience_in_years: this.experienceInYears(start_year, end_year) || null,
              experience_in_months: this.experienceInMonths(start_month, end_month) || null,
            };
          }
        )
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.ExperienceInfos
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.initFetchExperienceInfoOptions().then((res) => {
      if (res) this.isFetchingOptions = false;
    });
  },
  methods: {
    ...mapActions([
      "fetchCandidateExperiences",
      "uploadCandidateExperience",
      "initFetchExperienceInfoOptions",
      "deleteCandidateExperience",
      "updateCandidateExperience",
    ]),
    experienceInYears(start_year, end_year) {
      let present_year = m(new Date()).year()
      return start_year && end_year ? m(end_year).diff(m(start_year)) : m(present_year).diff(start_year)
    },
    experienceInMonths(start_month, end_month) {
      let present_month = m(new Date()).month()
      return start_month && end_month ? m(end_month).diff(m(start_month)) : m(present_month).diff(start_month)
    },
    totalExperience(data) {
      if (data?.experience_in_years != null && data?.experience_in_months != null) {
        return data?.experience_in_years > 1
          ? data.experience_in_years + ' yrs ' || "--"
          : data.experience_in_years + ' yrs ' + data.experience_in_months + ' months ' || "--" 
      } else {
        return "--"
      }
    },
    async uploadExperienceInfo() {
      const isValid = await this.$refs.ExperienceInfos.validate();
      this.errors = Object.values(this.$refs.ExperienceInfos.errors).flat();
      if (!isValid) {
        this.cardHasError = this.errors[0] || "please fill mandatory fields!";
        return;
      } 
      this.experienceUpload = true;
      this.experienceInfo = {
        organisation_type_id: this.profile?.org_type_id?.code
          ? this.profile?.org_type_id?.code
          : null,
        organisation_nature: this.profile?.organisation_nature !== "--"
          ? this.profile?.organisation_nature
          : null,
        organisation_additional_info: this.profile?.organisation_additional_info
          ? this.orgAdditionalInfo(this.profile?.organisation_additional_info)
          : null,
        organisation_additional_info_other: this.TypeisOther && this.profile?.organisation_additional_info_other !== "--"
          ? this.profile?.organisation_additional_info_other
          : null,
        organisation_location_id: this.profile?.organisation_location_id?.code !== "--"
          ? this.profile?.organisation_location_id?.code
          : null,
        organisation_bedcount: this.profile?.organisation_bedcount != "--"
          ? parseInt(this.profile?.organisation_bedcount)
          : null,
        direct_reports_count: this.profile?.direct_reports_count !== "--"
          ? parseInt(this.profile?.direct_reports_count)
          : null,
        is_current_org: this.profile?.is_current_org !== null
          ? this.profile?.is_current_org
          : null,
        start_month:
          this.profile?.start_month_year
            ? m(this.profile?.start_month_year).month() + 1
            : null,
        start_year:
          this.profile?.start_month_year
            ? m(this.profile?.start_month_year).year()
            : null,
        end_month:
          this.profile?.end_month_year
            ? m(this.profile?.end_month_year).month() + 1
            : null,
        end_year:
          this.profile?.end_month_year
            ? m(this.profile?.end_month_year).year()
            : null,
        designation: this.profile?.designation !== null
          ? this.profile?.designation
          : null,
        level_id: this.profile?.exp_level_id?.code !== null
          ? this.profile?.exp_level_id?.code
          : null
      };
      this.saveExperience(this.experienceInfo);
    },
    saveExperience(data) {
      let appendAction;
      if (data && this.edit_experience_id == null) {
        appendAction = this.uploadCandidateExperience(this.experienceInfo);
      } 
      else {
        this.experienceInfo = {
          ...data,
          experience_id: this.edit_experience_id
        }
        appendAction = this.updateCandidateExperience(this.experienceInfo);
      }
      Promise.all([appendAction]).then((res) => {
        this.edit_experience_id = null;
        this.resetFields();
        this.clearFields();
      });
    },
    orgAdditionalInfo(data) {
      let preferredarr = [];
      let value = data.code
      preferredarr.push(value);
      return preferredarr;
    },
    resetFields() {
      this.profile = {},
      this.edit_experience_id = null;
      this.$emit("tooltipHide");
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.ExperienceInfos.reset();
        this.$refs.ExperienceInfos.errors.clear;
      });
      this.cardHasError = "";
      this.multiFormEdited({ ExperienceInfos: false });
      this.multiFormEdited({ showError: false });
    },
    deleteExperience(data) {
      this.$emit("tooltipHide");
      this.deleteModal = true;
      this.currentConfirmModal = false;
      this.confirmModal.modalContent = `Do you want to delete this Experience ?`;
      this.confirmModal.buttons = ["Cancel", "Confirm"],
      this.delete_experience_id = data.experience_id;
      this.confirmModal.isShowPopup = true;
    },
    editExperience(data) {
      let el = this.$el.getElementsByClassName("ExperienceInformation")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        ...this.profile,
        org_type_id: {
          code: data?.organisation_type_id ? data?.organisation_type_id : null,
          label: data?.organisation_type ? data?.organisation_type : null
        },
        organisation_nature: data?.organisation_nature,
        organisation_additional_info_other: data?.organisation_additional_info_other
          ? data?.organisation_additional_info_other
          : null,
        organisation_additional_info: {
          code: data?.organisation_additional_info_id,
          label: data?.organisation_additional_info
        },
        organisation_location_id: {
          code: data?.organisation_location_id ? data?.organisation_location_id : null,
          label: data?.organisation_location ? data?.organisation_location : null
        },
        organisation_bedcount: data?.organisation_bedcount
          ? data?.organisation_bedcount
          : null,
        direct_reports_count: data?.direct_reports_count
          ? data?.direct_reports_count
          : null,
        is_current_org: data?.is_current_org,
        is_current_exp: data?.is_current_exp,
        designation: data?.designation,
        exp_level_id: {
          code: data?.level_id,
          label: data?.level_name
        },
        start_month_year:
          data.start_year && data.start_month != "--"
            ? data.start_month < 10
              ? `${data.start_year}-0${data.start_month}`
              : `${data.start_year}-${data.start_month}`
            : null,
        end_month_year:
          data.end_year && data.end_month != "--"
            ? data.end_month < 10
              ? `${data.end_year}-0${data.end_month}`
              : `${data.end_year}-${data.end_month}`
            : null,
        designation: data.designation
          ? data.designation 
          : null,
        level: {
          label: data.level_name,
          code: data.level_id
        },
      };
      this.edit_experience_id = data.experience_id;
    },
    confirmModalCallBack(action) {
      if (this.deleteModal) {
        if (action === "Confirm") {
          this.deleteCandidateExperience(this.delete_experience_id);
        }
      }
      this.delete_experience_id = null;
      this.resetFields();
      this.clearFields();
      this.deleteModal = false;
      this.currentConfirmModal = false;
      this.confirmModal.isShowPopup = false;
    },
    additionalInfo(data) {
      if (data?.organisation_additional_info) {
        return  data?.organisation_additional_info != "Other"
          ? data?.organisation_additional_info
          : data?.organisation_additional_info_other
            ? `Other - ${data?.organisation_additional_info_other}`
            : "--"
      }
    },
    formatDate(month, year) {
        let date = month && year ? new Date(`${year},${month},01`) : new Date();
        return m(date)?.format("MMM YYYY") ? m(date)?.format("MMM YYYY") : "--";
    },
  },
};
</script>