<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isContactInfo" />
    <div v-else class="contact-info">
      <div class="cardError">
      <div v-if="cardError" class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      </div>
      <CCard
        class="has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">Contact Information</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateContactInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['personalInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetContactData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="contactInfo" v-slot="{ handleSubmit }">
                <form
                  id="contactInfo"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_email"
                            class="required col-lg-12 col-md-12"
                            >Email</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="email"
                                :disabled="true"
                                :value="profile.email"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_alternate_email"
                            class="col-lg-12 col-md-12"
                            >Alternate Email</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                id="txt1"
                                ref="txt1"
                                name="alternate_email"
                                :value="profile.alternate_email"
                                @change="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow
                          class="row mb-3"
                          :class="profile.phone ? '' : 'requiredfield'"
                        >
                          <label
                            name="lbl_phone"
                            class="required col-lg-12 col-md-12"
                            >Phone</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              ref="phone"
                              :rules="{
                                required: true,
                                phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                              }"
                              v-slot="{ errors }"
                            >
                              <PhoneInputwithCountry
                                name="phone"
                                :value="profile.phone"
                                :options="
                                  options && options['dialingCode']
                                    ? options['dialingCode']
                                    : []
                                "
                                @change="handleInput"
                                :error="errors[0]"
                                :disabled="isDisabled"
                                ref="phoneNo"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_alternate_phone"
                            class="col-lg-12 col-md-12"
                            >Alternate Phone</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              ref="alternate_phone"
                              :rules="{ phone_rule: /^\+\d{1,3}\^\d{1,15}$/ }"
                              v-slot="{ errors }"
                            >
                              <PhoneInputwithCountry
                                name="alternate_phone"
                                :value="profile.alternate_phone"
                                :options="
                                  options && options['dialingCode']
                                    ? options['dialingCode']
                                    : []
                                "
                                @change="handleInput"
                                :disabled="isDisabled"
                                :error="errors[0]"
                                ref="alternatePhone"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            v-if="
                              profile.preferred_contact_method === null ||
                              profile.preferred_contact_method === ''
                            "
                            class="col-lg-12 col-md-12"
                            >WhatsApp</label
                          >
                          <label
                            v-else-if="
                              profile.preferred_contact_method.some(
                                (el) => el.id == 'whatsapp'
                              )
                            "
                            class="col-lg-12 col-md-12 required"
                            >WhatsApp</label
                          >
                          <label v-else class="col-lg-12 col-md-12"
                            >WhatsApp</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              ref="whatsapp"
                              :rules="{
                                phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                                required: profile.preferred_contact_method.some(
                                  (el) => el.id == 'whatsapp'
                                ),
                              }"
                              v-slot="{ errors }"
                            >
                              <PhoneInputwithCountry
                                name="whatsapp"
                                :value="profile.whatsapp"
                                :options="
                                  options && options['dialingCode']
                                    ? options['dialingCode']
                                    : []
                                "
                                @change="handleInput"
                                :disabled="isDisabled"
                                :error="errors[0]"
                                ref="whatsappNo"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_preferred_contact_method"
                            class="col-lg-12 col-md-12"
                            >Preferred Contact Method</label
                          >
                          <div class="col infoicon-multi-select">
                            <Select
                              name="preferred_contact_method"
                              :value="profile.preferred_contact_method"
                              :options="
                                options && options['preferred_contact_method']
                                  ? options['preferred_contact_method']
                                  : []
                              "
                              @input="handleChangeSelect"
                              :taggable="false"
                              :multiple="true"
                              :clearable="true"
                              :disabled="isDisabled"
                            />
                          </div>
                          <div class="position-relative multi-select-icon-div">
                            <CIcon
                              name="cil-info"
                              class="hinticon-multi-select"
                              v-c-tooltip="'Select one or more'"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_linkedin_address"
                            class="col-lg-12 col-md-12"
                            >LinkedIn Address</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="linkedin_address"
                              :value="profile.linkedin_address"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label name="lbl_skype_id" class="col-lg-12 col-md-12"
                            >Skype</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="skype_id"
                              :value="profile.skype_id"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_country_id"
                            class="col-lg-12 col-md-12"
                            >Currently Working In</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="working_country_id"
                              :value="profile.working_country_id"
                              :options="
                                options && options.countryInfo
                                  ? options.countryInfo
                                  : []
                              "
                              @change="handleChangeSelect"
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :disabled="isDisabled"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label name="lbl_skype_id" class="col-lg-12 col-md-12"
                            >Personal Website</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="personal_website"
                              :value="profile.personal_website"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";

import { required, email, numeric } from "vee-validate/dist/rules";

import { regex } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
export default {
  mixins: [ProfileSection],
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
    };
  },
  computed: {
    ...mapGetters(["candidateProfile", "dialingCodeWithCountryCode"]),
    options() {
      return {
        countryInfo: this.locationCountryInfo || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        preferred_contact_method: [
          { id: "phone", label: "Phone" },
          { id: "whatsapp", label: "WhatsApp" },
          { id: "email", label: "Email" },
          { id: "sms", label: "SMS" },
        ],
      };
    },
    getCountry() {
      return this.locationCountryInfo.filter(
        (val) => val.code === this.profile.country_id
      )[0];
    },
    isContactInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setContactData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["updateCandidate"]),
    setContactData(profileData) {
      this.profile = {
        customer_uid: profileData?.customer_uid,
        candidate_uid: profileData?.candidate_uid,
        email: profileData?.email,
        alternate_email: this.isSameEmail(
          profileData?.email,
          profileData?.alternate_email
        )
          ? null
          : profileData?.alternate_email,
        phone: profileData.phone !== null ? profileData.phone : "",
        alternate_phone:
          profileData.alternate_phone !== null
            ? profileData.alternate_phone
            : "",
        whatsapp: profileData.whatsapp !== null ? profileData.whatsapp : "",
        preferred_contact_method:
          profileData.preferred_contact_method !== null
            ? this.preferredContact(profileData.preferred_contact_method)
            : [],
        linkedin_address: profileData?.linkedin_address,
        skype_id: profileData?.skype_id,
        personal_website: profileData?.personal_website,
        working_country_id: this.locationCountryInfo.filter(
          (val) => val.code === profileData.country_id
        )[0],
      };
      return this.profile;
    },
    isSameEmail(email1, email2) {
      if (email1 && email2) {
        const email_1 = email1.toLowerCase().trim();
        const email_2 = email2.toLowerCase().trim();
        return email_1 === email_2 || false;
      }
      return false;
    },
    async updateContactInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.contactInfo.validate();
      this.errors = Object.values(this.$refs.contactInfo.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError = this.errors[0] || "please fill mandatory fields!";
        return;
      }
      this.payload = {
        ...this.payload,
        candidate_uid: this.profile?.candidate_uid,
        email: this.profile?.email,
        phone: this.profile?.phone,
        alternate_phone: this.profile?.alternate_phone,
        whatsapp: this.profile?.whatsapp,
        linkedin_address: this.profile?.linkedin_address,
        skype_id: this.profile?.skype_id,
        personal_website: this.profile?.personal_website,
        country_id: this.profile?.working_country_id?.code,
      };
      if (
        !this.isSameEmail(this.profile?.email, this.profile?.alternate_email) ||
        !this.isSameEmail(
          this.profile?.alternate_email,
          this.candidateProfile?.alternate_email
        )
      ) {
        this.payload = {
          ...this.payload,
          alternate_email: this.profile?.alternate_email,
        };
      } else if (this.payload?.alternate_email) {
        delete this.payload.alternate_email;
      }
      this.saveContactInfo(this.payload);
    },
    saveContactInfo(data) {
      const { customer_uid } = this.profile;
      let appendAction = this.updateCandidate({ data, customer_uid });
      Promise.all([appendAction]).then((res) => {
        this.clearFields();
        let el = this.$parent.$el.getElementsByClassName("border-top")[0];
        el.scrollIntoView({ iniline: "end" });
      });
    },
    resetContactData() {
      this.setContactData(this.candidateProfile);
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.contactInfo.errors.clear;
        this.$refs.contactInfo.reset();
      });
      this.$emit("tooltipHide");
      this.cardHasError = "";
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ showError: false });
    },
    preferredContact(data) {
      const val = data.split(", ");
      let preferredarr = [];
      for (var i = 0; i < val.length; i++) {
        var jsonObj = {
          id: val[i],
          label:
            val[i].charAt(0).toUpperCase() + val[i].substr(1).toLowerCase(),
        };
        preferredarr.push(jsonObj);
      }
      return preferredarr;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name === hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name === hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name === hasError.alternate_phone) {
          this.$refs.alternate_phone.errors.clear;
          this.$refs.alternate_phone.reset();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media all and (max-width: 480px) {
  .infoicon {
    width: 80%;
  }
}
</style>